import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import styles from './Panel.module.css';
import IconStack from '../IconStack/IconStack';
import CheckBox from '../CheckBox/CheckBox';

const ICON_TYPE = {
    success: { inner: 'tick', outer: 'bg-circle' },
    error: { inner: 'cross', outer: 'bg-circle' },
    warning: { inner: 'exclamation', outer: 'bg-triangle' },
};

const Panel = ({
    title,
    titleClass,
    details,
    children,
    bodyClass,
    className,
    type,
    radio,
    checkbox,
    checked,
    onTitleClick,
    darkTitle,
    actions,
    disabled,
}) => (
    <div className={cn(styles.wrapper, (radio || checkbox) && styles.radio, className)}>
        {!!title && (
            <div
                className={cn(
                    styles.title,
                    styles[`title-${type}`],
                    darkTitle && `client-secondary-colour-background ${styles.titleDark}`,
                    titleClass
                )}
                onClick={disabled ? undefined : onTitleClick}
            >
                {!!type && <IconStack className={styles.icon} size="1" {...ICON_TYPE[type]} />}
                {!!radio && (
                    <div
                        className={cn(styles.check, checked && styles.checked, checked && 'client-main-colour-border')}
                    />
                )}
                {!!checkbox && <CheckBox className={styles.checkbox} checked={checked} />}
                <span className={styles.titleText}>{title}</span>
                {!!details && (
                    <div className={styles.details} dangerouslySetInnerHTML={{ __html: details }}>
                    </div>
                )}
                {!!actions && <div className={styles.actions}>{actions}</div>}
            </div>
        )}
        {!!children && (
            <div className={cn(styles.body, (radio || checkbox) && 'client-main-colour-border', bodyClass)}>
                {children}
            </div>
        )}
    </div>
);

Panel.propTypes = {
    disabled: PropTypes.bool,
    type: PropTypes.string,
    className: PropTypes.string,
    titleClass: PropTypes.string,
    titleIcon: PropTypes.string,
    title: PropTypes.node,
    details: PropTypes.node,
    bodyClass: PropTypes.string,
    children: PropTypes.node,
    radio: PropTypes.bool,
    checkbox: PropTypes.bool,
    checked: PropTypes.bool,
    onTitleClick: PropTypes.func,
    darkTitle: PropTypes.bool,
    actions: PropTypes.node,
};

Panel.TYPE = {
    SUCCESS: 'success',
    ERROR: 'error',
    WARNING: 'warning',
};

export default Panel;
