import React, { useCallback, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Field, useFormikContext } from 'formik';
import get from 'lodash/get';
import ReactTooltip from 'react-tooltip';
import Input from 'components/Input/Input';
import { LINK_TYPE } from 'constants.js';
import { Cmp } from 'components/Dev/Cmp';
import useIsInstanceEditor from 'hooks/useIsInstanceEditor';
import { t } from 'utils';
import styles from './LinkTypeField.module.scss';
import LinkTypeFieldExtra from './LinkTypeFieldExtra';
import LinkTypeAssetLibrary from './LinkTypeAssetLibrary';

const TEXTS_MAP = {
    [LINK_TYPE.EMAIL]: 'email',
    [LINK_TYPE.URL]: 'web_link',
    [LINK_TYPE.LANDING_PAGE]: 'landing',
    [LINK_TYPE.ASSET_LIBRARY]: 'asset_library',
    [LINK_TYPE.UPLOAD]: 'file',
    [LINK_TYPE.FIXED_URL]: 'fixed_url',
    [LINK_TYPE.PARTNER_URL]: 'partner_url',
    [LINK_TYPE.SALES_RESOURCE]: 'sales_resource',
    [LINK_TYPE.PAGE]: 'page',
};

const FALLBACK = 'fallback';
const FALLBACK_VALUES = [LINK_TYPE.FIXED_URL, LINK_TYPE.ASSET_LIBRARY, LINK_TYPE.PARTNER_URL];

const getLinkOption = (value, type, disabled, fields) => {
    const text = TEXTS_MAP[value];
    return {
        label: <span className={styles.label}>{t(`template.default.link.${text}`)}</span>,
        extra:
            value === LINK_TYPE.LANDING_PAGE || value === LINK_TYPE.PARTNER_URL ? null : (
                <LinkTypeFieldExtra value={value} type={type} disabled={disabled} fields={fields} />
            ),
        value,
        tip: disabled ? undefined : t(`template.default.link.${text}_tip`),
    };
};

const isLinkEditDisabled = type => type === LINK_TYPE.LANDING_PAGE || type === LINK_TYPE.ASSET_LIBRARY;

function LinkTypeField({
    linkOptions,
    disabled,
    linkEditableField = 'isLinkEditable',
    typeField = 'type',
    urlField = 'url',
    urlTypeField = 'urlType',
    fallbackTypeField = 'fallbackType',
    fallbackUrlField = 'fallbackUrl',
}) {
    const { values, setFieldValue, setFieldTouched } = useFormikContext();
    const isInstanceEditor = useIsInstanceEditor();
    const fields = useMemo(
        () => ({ urlField, urlTypeField, fallbackTypeField, fallbackUrlField, linkEditableField }),
        [urlField, urlTypeField, fallbackTypeField, fallbackUrlField, linkEditableField]
    );

    const [isFallbackEnabled, setIsFallbackEnabled] = useState(
        !isInstanceEditor && get(values, typeField) === LINK_TYPE.LANDING_PAGE
    );

    useEffect(() => {
        ReactTooltip.rebuild();
    }, [isFallbackEnabled]);

    const onTypeChange = useCallback(
        ({ target: { value } }) => {
            setFieldValue(urlField, value === LINK_TYPE.LANDING_PAGE ? 'global_landingpage' : '', false);
            setFieldTouched(urlField, false, false);
            // Clean up support fields.
            setFieldValue(urlTypeField, '', false);
            setFieldValue(fallbackTypeField, '', false);

            if (isLinkEditDisabled(value)) {
                setFieldValue(linkEditableField, false);
            }
            setIsFallbackEnabled(value === LINK_TYPE.LANDING_PAGE);
        },
        [setFieldValue, urlField, urlTypeField, fallbackTypeField, linkEditableField]
    );

    const onFallbackTypeChange = useCallback(() => {
        setFieldValue(fallbackTypeField, '', false);
        setFieldTouched(fallbackTypeField, false, false);
    }, [setFieldValue, fallbackTypeField]);

    if (isInstanceEditor && values.type === LINK_TYPE.ASSET_LIBRARY) {
        return <LinkTypeAssetLibrary />;
    }

    return (
        <Cmp name="LinkTypeField" tag="div" path="components/EditTemplate/LinkTypeField/LinkTypeField.js">
            <Field
                component={Input}
                name={typeField}
                vertical
                type={Input.TYPE.RADIO}
                className={styles.options}
                onChange={onTypeChange}
                disabled={disabled}
                options={linkOptions.map(value => getLinkOption(value, values.type, disabled, fields))}
            />
            {isFallbackEnabled && (
                <>
                    <p className={styles.groupLabel}>{t('template.default.link.fallback_url')}</p>

                    <Field
                        component={Input}
                        name={fallbackTypeField}
                        vertical
                        type={Input.TYPE.RADIO}
                        className={styles.options}
                        onChange={onFallbackTypeChange}
                        options={FALLBACK_VALUES.map(value => getLinkOption(value, FALLBACK, disabled, fields))}
                    />
                </>
            )}
        </Cmp>
    );
}

LinkTypeField.propTypes = {
    linkOptions: PropTypes.array,
    disabled: PropTypes.bool,
    linkEditableField: PropTypes.string,
    typeField: PropTypes.string,
    urlField: PropTypes.string,
    urlTypeField: PropTypes.string,
    fallbackTypeField: PropTypes.string,
    fallbackUrlField: PropTypes.string,
};

export default LinkTypeField;
