import { useCallback, useContext } from 'react';
import activeDataContext from 'context/activeDataContext';
import useIsInstanceEditor from 'hooks/useIsInstanceEditor';
import useIsSocialPostInstanceEditor from 'hooks/useIsSocialPostInstanceEditor';
import { isEditableDataItem, OPTIONS_GROUPS } from '../../utils';

const getOriginalValue = (dataType, data) => {
    if (dataType === OPTIONS_GROUPS.MAIN || dataType === OPTIONS_GROUPS.LINKS) {
        return data;
    }

    return data.value;
};

const usePreBuiltEditField = (name, dataType, fieldData, item = {}) => {
    const { setActiveData } = useContext(activeDataContext);
    const isCampaignInstance = useIsInstanceEditor();
    const isSocialPostInstance = useIsSocialPostInstanceEditor();
    const isEditable = isEditableDataItem(dataType, item, isCampaignInstance || isSocialPostInstance);

    return [
        useCallback(
            e => {
                e.stopPropagation();
                if (fieldData?.isDeleted || !isEditable) {
                    return;
                }
                const extra = {};
                if (dataType === OPTIONS_GROUPS.IMAGES && !fieldData.options?.length) {
                    extra.options = fieldData?.isIdentifierEnabled ? [] : [{}];
                }

                setActiveData(null);
                setTimeout(() =>
                    setActiveData({
                        ...item,
                        ...(dataType === OPTIONS_GROUPS.MAIN ? { value: fieldData || item.value } : fieldData),
                        ...extra,
                        fieldName: name,
                        dataType,
                        originalValue: getOriginalValue(dataType, fieldData),
                    })
                );
            },
            [fieldData, dataType, name, item, setActiveData, isEditable]
        ),
        isEditable,
    ];
};

export default usePreBuiltEditField;
