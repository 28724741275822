import React, { useContext, createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const ShowComponentNames = createContext(false);

export function ComponentNamesProvider({ children, show = false }) {
  const [showNames] = useState(show || !!localStorage.getItem('dev'));
  return (
    <ShowComponentNames.Provider value={showNames}>
      {children}
    </ShowComponentNames.Provider>
  );
}

ComponentNamesProvider.propTypes = {
  children: PropTypes.node,
  show: PropTypes.bool,
};

const cutPath = (path) => {
  const L = 20;
  const len1 = Math.min(path.length, L);
  const len2 = path.length > L ? Math.min(path.length - L, L) : 0;
  const mid = path.length > (L + L) ? '...' : '';
  return [path.substr(0, len1), path.substr(-len2)].join(mid);
}

export function Cmp({ name, tag, path, props, v = false, children = null, ...more }) {
  const Root = tag || React.Fragment;
  const rootProps = tag ? { 'data-component': name, ...props, ...more } : {};
  const showNames = useContext(ShowComponentNames);
  const nameProps = { style: { color: '#00e5ff' }, title: path, children: name };
  const pathProps = path ? { style: { color: '#e500ff' }, title: path, children: cutPath(path) } : {};

  const theName = (
    <>
      <div {...nameProps} />
      {!!path && <div {...pathProps} />}
    </>
  );

  return (
    <Root {...rootProps}>
      {
        !tag &&
          <div {...rootProps}>
            {
              !!(v || showNames) && theName
            }
          </div>
      }
      {
        !!(v || showNames) && !!tag && theName
      }
      {children}
    </Root>
  );
}

Cmp.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string,
  path: PropTypes.string,
  props: PropTypes.shape({}),
  tag: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.elementType,
  ]),
  v: PropTypes.bool,
};
